label {
  line-height: 0;
  margin-right: 8px;
}

input[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

input[type='radio'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #1E1E1E;

  transition: 180ms ease-in-out;
}

.checkbox.circle {
  border-radius: 50%;
}

.checkbox.checkbox--active {
  background-color: #1E1E1E;
  transition: 180ms ease-in-out;
}

.checkbox.checkbox--active.true {
  background-color: #4EE080 !important;
  border-color: #4EE080 !important; 
}